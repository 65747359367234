@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Urbanist", "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  scroll-behavior: smooth;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #dbe2ef;
  border-radius: 4px;
}

.arrow-hidden::-webkit-outer-spin-button,
.arrow-hidden::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-hidden {
  appearance: textfield;
}


/* BLOG DESCRIPTION STYLE STARTS HERE */
/* Styles for h1 tag */
.description h1 {
  color: black;
  font-size: 35px;
  line-height: 50px;
  font-weight: bold;
  /* margin: 0px 0 0px 0; */
}

/* Styles for h2 tag */
.description h2 {
  color: black;
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  margin: 20px 0 0px 0;
}

/* Styles for h3 tag */
.description h3 {
  color: black;
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  margin: 12px 0 0px 0;
}

/* Styles for p tag */
.description p {
  color: #404040;
  font-size: 16px;
  line-height: 30px;
}

/* Styles for a tag */
.description a {
  color: #008cff;
  font-weight: 600;
}

/* Styles for unordered list */
.description ul {
  list-style-type: disc;
  color: #404040;
}

/* Styles for ordered list */
.description ol {
  list-style-type: decimal;
  color: #404040;
}

/* Styles for list items */
.description li {
  margin-bottom: 10px;
  margin-left: 40px;
}


/* responsive */
@media screen and (max-width: 640px) {
  .description h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .description h2 {
    font-size: 23px;
    line-height: 38px;
  }

  .description h3 {
    font-size: 20px;
    line-height: 33px;
  }

  .description li {
    margin-left: 25px;
  }
}